<!--
 * @Author: zx
 * @Date: 2022-09-13 11:35:09
 * @LastEditTime: 2023-01-10 17:41:07
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="page">
    <div v-if="!this.childAppItem.is_install" class="page-edit">
      <p class="page-name">
        <!-- <svg width="16" height="16" :style="'color:'+ childAppItem.color">
          <use :xlink:href="childAppItem.icon.indexOf('#')===-1? '#' + childAppItem.icon : childAppItem.icon" />
        </svg> -->
        <i 
          class="iconfont" 
          :class="childAppItem.icon? childAppItem.icon : 'icon-a-5-1zhiliang'"
          :style="'color:'+ (childAppItem.color?childAppItem.color:'#e06363')"
          style="margin-right:8px">
        </i>
        {{ childAppItem.name }}
      </p>
      <el-button v-if="!this.childAppItem.is_install" type="primary" size="mini" @click="editPage">编辑</el-button>
    </div>
    <div class="page-parser" v-loading="showParser">
      <Parser v-if="inited" :pageType="pageType" :isNormal="true" />
      <el-empty v-else description="暂无有效页面数据"></el-empty>
    </div>
  </div>
</template>
<script>
import Parser from '@/components/parser/Index'
import { pageFromDetail } from '@/api/saas'
import { isJSONStr } from '@/utils/tools';
import { mapState } from 'vuex';
import { Empty } from 'element-ui';
  export default {
    components:{
      'el-empty': Empty,
      Parser
    },
    computed: {
      ...mapState(['childAppItem'])
    },
    props:{
    },
    data() {
      return {
        pageUUID: '',
        pageType: '',
        name: '',
        showParser:false,
        inited:false,
      }
    },
    created() {
      const { pageUUID, type, name } = this.$route.query
      this.pageUUID = pageUUID
      this.pageType = type
      this.name = name
    },
    
    mounted() {
      this.getViewData()
      console.log(this.childAppItem)
    },
    methods:{
          /**
       * @desc: 获取dataview配置数据
       */
      getViewData() {
        this.loading = false;
        this.showParser = true;
        pageFromDetail({
          __method_name__: 'dataList',
          page_uuid: this.pageUUID,
          object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
          view_uuid: 'view61b951c6a8186',
          transcode: 0
        })
          .then((res) => {
            this.showParser = false;
            const { page_data } = res?.data?.data?.[0] || {};
            const { componentData, editorConfig, editorType, statusConfig = [] } = isJSONStr(page_data ) ? JSON.parse(page_data) : page_data;
            this.pageType = editorType
            sessionStorage.setItem('statusConfig', JSON.stringify(statusConfig));
            this.$store.commit('setConfigData', {
              componentData,
              editorConfig,
              editorType
            });
            sessionStorage.setItem('editorType', editorType);
            this.inited = true;
          })
          .catch(() => {
            this.showParser = false;
          })
      },
      editPage() {
        // 如果为全屏模式，那么相对应的跳转全屏编辑
        const neefFull = true
        if(window.location.pathname === '/full-page-parser' || neefFull) {
          const { pageUUID, pageType, name, id } = this.$route.query
          this.$router.push({
            name: 'FullPageEdit',
            query: { 
              pageUUID: pageUUID, 
              id: id, 
              type: pageType,
              name: name
            } 
          })
        } else {
          const { pageUUID, pageType, name, id } = this.$route.query
          this.$router.push({
            path: '/application/page-edit',
            query: { 
              pageUUID: pageUUID, 
              id: id, 
              type: pageType,
              name: name
            } 
          })
        }
      },
      /* 判断编辑 */
      showEdit() {
        return this.$route.query.create_user_id && +this.$route.query.create_user_id === JSON.parse(localStorage.getItem('userInfo')).id
      }
    }
  }
</script>
<style lang='less' scoped>
  .page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .page-edit {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      box-sizing: border-box;
      background-color: #fff;
      border-bottom: 1px solid #eee;
      .page-name{
        display: flex;
        align-items: center;
      }
    }
    .page-parser {
      flex: 1;
      overflow-y: auto;
      display: flex;
      justify-content: center;
    }
  }
</style>